<template>
  <transition name="float-btn-area">
    <div class="float-btn-area" v-show="visible">
      <img :src="imgSrc" width="100%" @click="onClick" class="pointer" />
    </div>
  </transition>
</template>

<script>
export default {
  name: "FloatingStartButton",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.float-btn-area {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 10px 10px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.float-btn-area img {
  cursor: pointer;
  max-width: 493px;
}

.float-btn-area-enter,
.float-btn-area-leave-to {
  opacity: 0;
}

.float-btn-area-enter-active,
.float-btn-area-leave-active {
  transition: opacity 0.6s;
}
</style>
