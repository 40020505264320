<script>
export default {
  name: "RadioButton",
  props: {
    value: String,
  },
  render(createElement) {
    let value = this.$el ? this.$el.innerText : "";
    if (this.value) {
      value = this.value;
    }

    const componentData = {
      staticClass: "radio-button",
      class: {
        selected: this.$parent.value === value,
      },
    };

    const inputData = {
      model: {
        prop: "model",
      },
      domProps: {
        checked: this.$parent.value === value,
        model: this.$parent.value,
      },
      attrs: {
        type: "radio",
        name: this.$parent.name,
        value: value,
      },
      on: {
        change: (e) => {
          this.$parent.$emit("input", e.target.value);
        },
      },
    };

    this.$slots.default().unshift(createElement("i"));
    this.$slots.default().unshift(createElement("input", inputData));

    return createElement("label", componentData, this.$slots.default());
  },
};
</script>
