const localKey = "campaign";

export default {
  namespaced: true,
  state: {
    applied: false,
    sponsors: [],
  },
  getters: {
    getCampaign(state) {
      let campaign = Object.assign({}, state);
      if (localStorage.getItem(localKey)) {
        const localData = JSON.parse(localStorage.getItem(localKey));

        campaign.applied = localData.applied;
        campaign.sponsors = localData.sponsors;
      }

      return campaign;
    },
  },
  mutations: {
    applied(state, sponsors = []) {
      state.applied = true;
      state.sponsors = sponsors;

      localStorage.setItem(localKey, JSON.stringify(state));
    },
  },
  actions: {
    applied({ commit }, sponsors) {
      commit("applied", sponsors);
    },
  },
};
