<template>
  <div class="input-group" :class="{ ok: passed }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <label class="checkbox" v-for="(option, index) in options" :key="index">
      <input
        type="checkbox"
        :name="name"
        :value="option.label"
        @change.stop="onChange"
        @input.stop
        :checked="modelValue.includes(option.label)"
      /><i></i>{{ option.label }}
    </label>
  </div>
</template>

<script>
import { computed, toRaw } from "vue";
import Condition from "./Condition";

export default {
  name: "CheckBox",
  setup(props) {
    const values = computed({
      get: () => toRaw(props.modelValue),
    });

    return {
      values,
    };
  },
  props: {
    modelValue: {
      // v-model バインド用
      type: Array,
      required: false,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    about: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({ exclusive: null }),
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    passed: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    beExclusive() {
      return this.$props.modelValue.includes(this.params.exclusive);
    },
  },
  methods: {
    onChange(e) {
      const checked = e.target.checked;
      let values = toRaw(this.modelValue);
      if (checked) {
        values.push(e.target.value);
      } else {
        values = values.filter((value) => value !== e.target.value);
      }

      this.$emit("update:modelValue", Array.from(new Set(values)));
    },
    visible(option) {
      // 本当はoption.valueでやりたいがsenior_frontをもっと適切に整備したタイミングでやる
      return !this.beExclusive || option.label === this.params.exclusive;
    },
  },
  mixins: [Condition],
};
</script>
