<template>
  <div class="input-group" :class="{ ok: passed }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <div class="radio-group" :class="{ ng: failed }">
      <!-- selectedの評価は数値と文字列のいずれでも等価できるように演算子を == にした -->
      <label
        v-for="(option, index) in options"
        :key="index"
        class="radio-button"
        :class="{ selected: option.label == modelValue }"
      >
        <!-- senior_frontをもっと適切に整備したタイミングで:value="option.value"にする -->
        <input
          type="radio"
          :name="name"
          :value="option.label"
          @change="onChange"
          :checked="option.label == modelValue"
        /><i></i>{{ option.label }}
        <!-- iタグでラジオの選択状態の○を作っている -->
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Radio",
  props: {
    modelValue: {
      // v-model バインド用
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    passed: {
      type: Boolean,
      required: false,
    },
    failed: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    onChange(e) {
      this.$emit("update:modelValue", e.target.value); // v-model バインド用
    },
  },
};
</script>
