<template>
  <div>
    <app-header></app-header>
    <main>
      <router-view />
    </main>
    <app-footer></app-footer>
  </div>
</template>

<style lang="scss" scoped>
div {
  max-width: 800px;
  margin: 0 auto;
}
</style>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "Main",
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
