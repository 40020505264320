<template>
  <div class="input-group" :class="{ ok: passed }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <multiselect
      v-model="values"
      placeholder="選択してください"
      :options="conditionalOptions"
      mode="tags"
      :searchable="false"
      :clearOnSelect="false"
      @select="onSelect"
    ></multiselect>
  </div>
</template>

<style lang="scss">
@import "~@vueform/multiselect/themes/default.css";
.multiselect {
  margin-top: 5px;
}
.multiselect-option {
  &:hover {
    text-decoration: none;
  }

  &.is-pointed {
    background: #41b883;
    outline: none;
    color: #fff;
    text-decoration: none;
  }
}
</style>

<script>
import { computed } from "vue";
import Multiselect from "@vueform/multiselect";
import Condition from "./Condition";

export default {
  name: "MultiSelect",
  setup(props, { emit }) {
    const values = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });

    return {
      values,
    };
  },
  components: {
    Multiselect,
  },
  props: {
    modelValue: {
      // v-model バインド用
      type: Array,
      required: false,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    annotation: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({ exclusive: null }),
    },
    options: {
      type: Array,
      required: true,
    },
    passed: {
      type: Boolean,
      required: false,
    },
    failed: {
      type: Boolean,
      required: false,
    },
    clientCode: {
      // sponsor_type を受け入れる変数
      type: String,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    conditionalOptions() {
      return Object.fromEntries(
        this.options.filter(this.evaluate).map((v) => [v.label, v.label])
      );
    },
  },
  methods: {
    onSelect(selected) {
      let values = this.values;
      if (selected === this.params.exclusive) {
        values = [this.params.exclusive];
      } else {
        values = values.filter((v) => v !== this.params.exclusive);
        values.push(selected);
      }
      this.$emit("update:modelValue", values);
    },
  },
  mixins: [Condition],
};
</script>
