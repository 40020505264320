<template>
  <img
    :src="imgSrc"
    class="to-step1-btn"
    :class="{ animation }"
    @click="onClick"
  />
</template>

<script>
export default {
  name: "StartButton",
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    animation: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.to-step1-btn {
  cursor: pointer;
}

.animation {
  animation-name: btnWave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.8s;
}
</style>
