<template>
  <p class="emphasized-text">{{ text }}</p>
</template>

<script>
export default {
  name: "EmphasizedText",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
