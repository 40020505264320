<template>
  <div class="notice">
    このフォームで⼊⼒いただいた情報は、会員登録を適切に実施し管理するため・キャンペーン応募を適切に管理するため・キャンペーン等でプレゼント商品等をお届けするために利⽤いたします。
    これ以外の個⼈情報の利⽤⽬的、その他お取り扱い等については 「<a
      :href="src"
      target="_blank"
      >個⼈情報の取り扱いについて</a
    >」 を必ずご確認いただき、同意の上ご応募ください。
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyNotice",
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.notice {
  font-size: 0.6rem;
  color: #999898;
  line-height: 1;
}
</style>
