<template>
  <div class="complete-header-area">
    <img
      src="https://mamab.jp/uploads/web_image/comp_logo.png"
      class="complete-logo"
    />
  </div>
</template>

<style lang="scss" scoped>
div {
  &.complete-header-area {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 50px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  img {
    &.complete-logo {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 160px;
    }
  }
}
</style>

<script>
export default {
  name: "CompleteHeader",
};
</script>
