import BloodPressure from "@/assets/questionnaire/q1_blood_pressure.json";
import HealthAndInsurance from "@/assets/questionnaire/q2_health_and_insurance.json";
import LifeStyle from "@/assets/questionnaire/q4_life_style.json";
import RealEstate from "@/assets/questionnaire/q5_real_estate.json";
import LongTermCare from "@/assets/questionnaire/q7_longtermcare.json";
import Housing from "@/assets/questionnaire/q9_housing.json";
import CreditCard from "@/assets/questionnaire/q10_credit_card.json";
import Family from "@/assets/questionnaire/q11_family.json";
import Karadanotewater from "@/assets/questionnaire/q12_karadanotewater.json";
import Coop from "@/assets/questionnaire/q13_coop.json";
import Power from "@/assets/questionnaire/q14_power.json";

export default {
  namespaced: true,
  state: () => ({}),
  getters: {
    step2() {
      return {
        list: [
          BloodPressure,
          Family,
          HealthAndInsurance,
          LongTermCare,
          LifeStyle,
          Housing,
          RealEstate,
          Coop,
          Karadanotewater,
          Power,
          CreditCard,
        ],
      };
    },
  },
};
