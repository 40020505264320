<template>
  <section class="guideline">
    <h3>{{ title }}</h3>
    <div><slot></slot></div>
  </section>
</template>

<script>
export default {
  name: "Guideline",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.guideline {
  background-color: #fff;
  padding: 1rem 0.6rem;
}

h3 {
  padding: 0.5rem 10px;
  font-size: 0.85rem;
  background-color: #ffdbdb;
  font-weight: bold;
}

div {
  font-size: 0.8rem;
}
</style>
