<template>
  <Field
    :name="name"
    :label="label"
    :rules="rules"
    :vid="vid"
    v-slot="{ field, errors, meta }"
    :modelValue="modelValue"
    @update:modelValue="
      $emit('update:modelValue', $event);
      $emit('input', $event);
    "
    mode="eager"
    as="div"
  >
    <div :class="{ ok: !any && meta.valid, 'input-group': !vid }">
      <label v-if="!vid"
        ><span class="status" :class="{ any }"></span
        >{{ title ? title : label }}</label
      >
      <ul class="input-errors" v-if="errors.length > 0 && !vid">
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
      <input
        :type="type"
        :class="{ ng: meta.touched && !meta.valid }"
        @keyup="$emit('keyup', $event)"
        @blur="$emit('blur', $event)"
        v-bind="{ ...$attrs, ...field }"
      />
      <slot></slot>
    </div>
  </Field>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: true,
    },
    rules: {
      type: [Object, String],
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    any() {
      if (typeof this.rules === "string") {
        return this.rules.indexOf("required") === -1;
      }
      return !this.rules.required;
    },
  },
};
</script>
