<script>
export default {
  name: "RadioGroup",
  model: {
    prop: "value",
  },
  props: {
    value: String,
    name: String,
  },
  render(createElement) {
    return createElement(
      "div",
      {
        staticClass: "radio-group",
        attrs: {
          name: this.name,
        },
      },
      this.$slots.default
    );
  },
};
</script>
