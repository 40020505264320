<script>
var _etmc = _etmc || [];
export default {
  data() {
    return {
      orgId: "514008293",
    };
  },
  methods: {
    mcPageView() {
      _etmc.push(["setOrgId", this.orgId]);
      const email = localStorage.getItem("email");
      if (email) {
        _etmc.push(["setUserInfo", { email: email }]);
      }
      _etmc.push([
        "trackPageView",
        {
          item: "healthyway_campaign_top",
        },
      ]);
    },
    mcTopApply(email) {
      _etmc.push(["setOrgId", this.orgId]);
      _etmc.push(["setUserInfo", { email: email }]);
      _etmc.push([
        "trackPageView",
        {
          item: "healthyway_campaign_top_apply",
        },
      ]);
      // LocalStorageにメールアドレスを保存する
      localStorage.setItem("email", email);
    },
    mcStep1Next() {
      _etmc.push(["setOrgId", this.orgId]);
      const email = localStorage.getItem("email");
      if (email) {
        _etmc.push(["setUserInfo", { email: email }]);
      }
      _etmc.push([
        "trackPageView",
        {
          item: "healthyway_campaign_step1_next",
        },
      ]);
    },
    mcStep2Next() {
      _etmc.push(["setOrgId", this.orgId]);
      const email = localStorage.getItem("email");
      if (email) {
        _etmc.push(["setUserInfo", { email: email }]);
      }
      _etmc.push([
        "trackPageView",
        {
          item: "healthyway_campaign_step2_next",
        },
      ]);
    },
    mcConfirmApply() {
      _etmc.push(["setOrgId", this.orgId]);
      const email = localStorage.getItem("email");
      if (email) {
        _etmc.push(["setUserInfo", { email: email }]);
      }
      _etmc.push([
        "trackPageView",
        {
          item: "healthyway_campaign_confirm_apply",
        },
      ]);
    },
  },
};
</script>
