import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/top",
    name: "Home",
    component: Home,
  },
  {
    path: "/step1",
    name: "Step1",
    component: () =>
      import(/* webpackChunkName: "step1" */ "../views/Step1.vue"),
  },
  {
    path: "/step2",
    name: "Step2",
    component: () =>
      import(/* webpackChunkName: "step2" */ "../views/Step2.vue"),
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: () =>
      import(/* webpackChunkName: "confirm" */ "../views/Confirm.vue"),
  },
  {
    path: "/complete",
    name: "Complete",
    component: () =>
      import(/* webpackChunkName: "complete" */ "../views/Complete.vue"),
  },
  {
    path: "/mypage",
    name: "Mypage",
    component: () =>
      import(/* webpackChunkName: "mypage" */ "../views/Mypage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/top",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

export default router;
