<template>
  <div class="input-group" :class="{ ok: passed }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <select
      :class="{ ng: failed }"
      :name="name"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">{{ params.placeholder }}</option>
      <!-- senior_frontをもっと適切に整備したタイミングで:value="option.value"にする -->
      <template v-if="!params.optgroup">
        <option
          v-for="(option, index) in options"
          :value="option.label"
          :key="index"
          :selected="option.label == modelValue"
        >
          {{ option.label }}
        </option>
      </template>
      <optgroup
        v-else
        v-for="(group, index) in options"
        :key="index"
        :label="group.label"
      >
        <!-- tagとvalueの組み合わせで :value="tag + option.value"とする予定 -->
        <option
          v-for="(option, index) in group.values"
          :value="option.label"
          :key="index"
          :selected="option.label == modelValue"
        >
          {{ option.label }}
        </option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectBox",
  props: {
    modelValue: {
      // v-model バインド用
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({ placeholder: null, suffix: null, optgroup: false }),
    },
    options: {
      type: Array,
      required: true,
    },
    passed: {
      type: Boolean,
      required: false,
    },
    failed: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
