import Vuex from "vuex";
import answer from "./answer";
import campaign from "./campaign";
import component from "./component";
import questionnaire from "./questionnaire";

function getDefaultState() {
  return {};
}

// 今後のために回答用にいれる箇所を修正
export default Vuex.createStore({
  strict: process.env.NODE_ENV !== "production", // 開発環境では厳格モードにする
  state: getDefaultState(),
  mutations: {},
  actions: {},
  modules: {
    answer,
    campaign,
    component,
    questionnaire,
  },
});
