import { configure, defineRule } from "vee-validate";
import { required, max, regex, email } from "@vee-validate/rules";
import isValidString from "./validations/isValidString";
import isValidStringOrEmpty from "./validations/isValidStringOrEmpty";
import prefectures from "./validations/prefectures";
import dayjs from "dayjs";
import { localize } from "@vee-validate/i18n";
const emojiRegex = require("emoji-regex");

export default function extendValidation() {
  defineRule("required", required);
  defineRule("max", max);
  defineRule("regex", regex);
  defineRule("email", email);

  defineRule("email_rfc", (value) => {
    if (!value) {
      return true;
    }

    // RFC違反が来た時点ですぐ弾く
    const rfc = /(\.\.)|(\.@)/;
    if (rfc.test(value)) {
      return false;
    }

    // 少なくともメールアドレスの体裁をしているか簡易チェック
    // @の前に1文字以上、@の後ろに 1文字以上 + . + 2文字以上あるか)
    const regEmail = /^.+@[a-zA-Z0-9]+\.[a-zA-Z0-9]{2,}$/;

    // メールアドレスの最低の体勢をなしていない場合はバリデーションエラーを出さない
    // このチェックをしないと、1文字入力した段階でエラーメッセージが表示され、
    // ユーザー体験にかかわってCV率低下が起こりうるため
    if (!regEmail.test(value)) {
      return true;
    }

    // Vee-Validate本家のEmailバリデーションを行う
    return email(value);
  });

  defineRule("dependency_string", (value, [address]) => {
    if (!value) {
      return true;
    }

    const regex = emojiRegex();

    return (
      isValidString(value, address) &&
      /^([^,隝濵栁德桒𠮷髙﨑彅籐㟢瀨𣘺槗栁桺隆琢羽𡈽黑靏邉邊塚都纊褜鍈銈蓜俉炻昱棈鋹曻丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏增墲夋奓奛奝奣妺孖寀甯寘寬尞岦岺峵崧嵓嵂嵭嶸嶹巐弡弴彧忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻柀桄棏﨓楨﨔榘槢樰橫橆橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵瀅瀇炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠繒罇羡茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒﨩隯霳霻靃靍靑靕顗顥飯飼餧館馞驎髜魵魲鮱鮻鰀鵰鵫鶴鸙橳緖珉鮏妤㈱㈲㊑㍿㊒🈶]+)$/u.test(
        value
      ) &&
      !value.match(regex)
    );
  });

  defineRule("date_format", (value, [format]) => {
    return dayjs(value, format).format(format) === value;
  });

  defineRule("age", (value, params, ctx) => {
    const today = new Date();
    const todayStr =
      today.getFullYear().toString().padStart(4, "0") +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      today.getDate().toString().padStart(2, "0");
    const age = Math.floor(
      (parseInt(todayStr, 10) - parseInt(value, 10)) / 10000
    );

    ctx.data = { age };
    return 20 <= age && age < 120;
  });

  defineRule("prefecture", (value) => {
    return prefectures.includes(value);
  });

  defineRule("valid_string_or_empty", (value, [address]) => {
    if (!value) {
      return true;
    }

    const regex = emojiRegex();

    return (
      isValidStringOrEmpty(value, address) &&
      /^([^,隝濵栁德桒𠮷髙﨑彅籐㟢瀨𣘺槗栁桺隆琢羽𡈽黑靏邉邊塚都纊褜鍈銈蓜俉炻昱棈鋹曻丨仡仼伀伃伹佖侒侊侚侔俍偀倢俿倞偆偰偂傔僴僘兊兤冝冾凬刕劜劦勀勛匀匇匤卲厓厲叝﨎咜咊咩哿喆坙坥垬埈埇﨏增墲夋奓奛奝奣妺孖寀甯寘寬尞岦岺峵崧嵓嵂嵭嶸嶹巐弡弴彧忞恝悅悊惞惕愠惲愑愷愰憘戓抦揵摠撝擎敎昀昕昻昉昮昞昤晥晗晙晴晳暙暠暲暿曺朎朗杦枻柀桄棏﨓楨﨔榘槢樰橫橆橾櫢櫤毖氿汜沆汯泚洄涇浯涖涬淏淸淲淼渹湜渧渼溿澈澵瀅瀇炅炫焏焄煜煆煇凞燁燾犱犾猤猪獷玽珖珣珒琇珵琦琪琩琮瑢璉璟甁畯皂皜皞皛皦益睆劯砡硎硤硺礰礼神祥禔福禛竑竧靖竫箞精絈絜綷綠繒罇羡茁荢荿菇菶葈蒴蕓蕙蕫﨟薰蘒﨡蠇裵訒訷詹誧誾諟諸諶譓譿賰賴贒赶﨣軏﨤逸遧郞鄕鄧釚釗釞釭釮釤釥鈆鈐鈊鈺鉀鈼鉎鉙鉑鈹鉧銧鉷鉸鋧鋗鋙鋐﨧鋕鋠鋓錥錡鋻﨨錞鋿錝錂鍰鍗鎤鏆鏞鏸鐱鑅鑈閒﨩隯霳霻靃靍靑靕顗顥飯飼餧館馞驎髜魵魲鮱鮻鰀鵰鵫鶴鸙橳緖珉鮏妤㈱㈲㊑㍿㊒🈶]+)$/u.test(
        value
      ) &&
      !value.match(regex)
    );
  });

  configure({
    generateMessage: localize("ja", {
      messages: {
        required: "{field}は必須です",
        max: "{field}は{length}文字以内にしてください",
        regex: "{field}の形式が正しくありません",
        date_format: "{field}の日付が正しくありません",
        dependency_string: "{field}に利用できない文字が含まれています",
        check_child_age: "{field}の日付が正しくありません",
        email: "{field}は有効な形式ではありません",
        email_rfc: "{field}は有効な形式ではありません",
        prefecture: "正しい都道府県名を入力してください",
        exist_emoji: "{field}は有効な形式ではありません",
        valid_string_or_empty: "{field}は有効な形式ではありません",
        age: (ctx) => {
          if (ctx.data.age < 20) {
            return `アンケートの都合上、応募は20才以上の方限定となります`;
          }
          return "{field}の日付が正しくありません";
        },
      },
    }),
  });
}
