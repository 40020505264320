import { createApp, h } from "vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import extendValidation from "./validation";
import RadioGroup from "@/components/RadioGroup.vue";
import RadioButton from "@/components/RadioButton.vue";
import TextInput from "@/components/TextInput.vue";
import VueAxios from "vue-axios";
import axios from "axios";
import mock from "./mock";
import VueScrollTo from "vue-scrollto";

require("@/assets/sass/main.scss");

extendValidation();

// APIの向き先を自ドメインによって変える
// 現行の構成だとステージングと本番でファイルを切り替えるのはリスクがあるため、
// フロント側で判定する
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

if (process.env.VUE_APP_USE_MOCK !== "false") {
  mock(axios);
}

const app = createApp({
  setup() {
    return {
      change() {},
      scroll(selector, block = "center") {
        document
          .querySelector(selector)
          .scrollIntoView({ behavior: "smooth", block });
      },
    };
  },
  render: () => h(App),
});
app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(VueScrollTo, {
    offset: -100,
  })
  .component("Field", Field)
  .component("Form", Form)
  .component("ErrorMessage", ErrorMessage)
  .component("RadioGroup", RadioGroup)
  .component("RadioButton", RadioButton)
  .component("TextInput", TextInput)
  .mount("#app");
