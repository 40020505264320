import { toRaw } from "vue";
import Radio from "@/components/Common/Radio";
import SelectBox from "@/components/Common/SelectBox";
import CheckBox from "@/components/Common/CheckBox";
import EmphasizedText from "@/components/Common/EmphasizedText";
import MultiSelect from "@/components/Common/MultiSelect";
import MultiDateTimePicker from "@/components/Common/MultiDateTimePicker";

export default {
  namespaced: true,
  state: () => {
    return {
      checkbox: CheckBox,
      select: SelectBox,
      radio: Radio,
      emphasizedText: EmphasizedText,
      multiSelect: MultiSelect,
      multiDatetimepicker: MultiDateTimePicker,
    };
  },
  getters: {
    components: (state) => (name) => {
      return state[name] ? toRaw(state[name]) : null;
    },
  },
  mutations: {
    addComponent(state, data) {
      Object.keys(data).forEach((key) => {
        state[key] = data[key];
      });
    },
  },
  actions: {
    register({ commit }, data) {
      if (!(data instanceof Object)) {
        console.error("引数はObjectで渡してください");
        return;
      }
      commit("addComponent", data);
    },
  },
};
