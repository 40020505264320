<template>
  <div class="input-group" :class="{ ok: passed }">
    <label><span class="status"></span>{{ question }}</label>
    <slot name="error"></slot>
    <div class="group" v-for="(n, index) of params.repeat" :key="index">
      <!-- 複数の希望日時にしか使われない想定なので一旦こういう形にしておく -->
      <span
        >第{{ index + 1 }}希望<span class="status" v-if="index === 0"></span
      ></span>
      <Datetime
        :id="'timepicker ' + index"
        :modelValue="values[index]"
        format="YYYY-MM-DDTHH:mm:ss"
        label="日時を選択してください"
        :min-date="minDate"
        :minute-interval="60"
        :overlay="true"
        :disabled-hours="params.disabledHours"
        @update:modelValue="changeValue(index, $event)"
      ></Datetime>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~vue-ctk-date-time-picker3/dist/vue-ctk-date-time-picker3.css";
.group {
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    width: 8rem;

    > .status {
      margin-left: 0.4rem;
    }
  }

  + .group {
    margin-top: 0.5rem;
  }
}
:deep {
  @media screen and (max-width: 415px) {
    .datetimepicker:not(.inline) {
      display: none !important;

      &.visible {
        display: flex !important;
      }
    }
  }
}
</style>

<script>
import Datetime from "vue-ctk-date-time-picker3";
import dayjs from "dayjs";
import "dayjs/locale/ja";

dayjs.locale("ja");
export default {
  name: "MultiDateTimePicker",
  components: {
    Datetime,
  },
  data() {
    return {
      values: [],
      minDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    };
  },
  props: {
    modelValue: {
      // v-model バインド用
      type: String,
      required: false,
    },
    question: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
      default: () => ({ repeat: 1, disabledHours: [] }),
    },
    passed: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  created() {
    const values = this.modelValue ? this.modelValue.split(",") : [];
    for (let i = values.length; i < this.$props.params.repeat; i++) {
      values.push(null);
    }
    this.values = values;
  },
  methods: {
    changeValue(index, $event) {
      this.values[index] = $event;
      // Vueに変更を検知させる
      this.values = [...this.values];
      this.$emit(
        "update:modelValue",
        this.values.filter((value) => !!value).join(",")
      );
    },
  },
};
</script>
