<template>
  <footer>
    <ul>
      <li>
        <a href="https://corp.karadanote.jp/">運営会社</a>
      </li>
      <li>
        <a href="https://corp.karadanote.jp/terms">利用規約</a>
      </li>
      <li>
        <a href="https://corp.karadanote.jp/privacy-handling"
          >プライバシーポリシー</a
        >
      </li>
      <li>
        <a href="https://corp.karadanote.jp/counseling">よくある質問</a>
      </li>
    </ul>
    <p>
      <a href="https://privacymark.jp/" class="pt-6"
        ><img
          src="https://mamab.jp/uploads/web_image/22000312_200_JP.png"
          width="50px"
      /></a>
      &copy;Karadanote Inc. All Rights Reserved.
    </p>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  border: 1px solid #fff;
  border-width: 0 1px 0 1px;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding-left: 0;
  border-top: 1px solid #fff;

  > li {
    flex: 1 1 50%;
    text-align: center;
    font-size: 0.7rem;
    border: 1px solid #fff;
    border-width: 0 1px 1px 0;

    &:nth-of-type(2n) {
      border-right-width: 0;
    }

    > a {
      display: block;
      color: #000;
      padding: 0.9rem 0;
    }
  }
}
p {
  padding: 0.9rem 0;
  text-align: center;
  font-size: 0.7rem;

  img {
    width: 50px;
  }

  > a {
    display: block;
    margin-bottom: 0.9rem;
    text-align: center;
  }
}
</style>

<script>
export default {
  name: "AppFooter",
};
</script>
