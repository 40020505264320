<template>
  <div class="sponsor">
    ▼協賛企業
    <ul>
      <li v-for="(name, index) in names" :key="index">{{ name }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SponsorList",
  props: {
    names: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsor {
  margin-top: 1rem; /* これは本当はコンポーネントの外に出したい */
}

ul {
  list-style: none;
  padding-left: 0;
}
</style>
