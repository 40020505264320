<template>
  <div>
    <complete-header v-if="this.$route.path === '/complete'"></complete-header>
    <template v-else>
      <img src="https://mamab.jp/uploads/web_image/senior_header.png" />
    </template>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import CompleteHeader from "@/components/CompleteHeader.vue";
export default {
  name: "AppHeader",
  components: {
    CompleteHeader,
  },
};
</script>
