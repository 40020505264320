import { toRaw } from "vue";

const sessionKey = "answer";

export default {
  namespaced: true,
  state: () => {
    const state = {};
    if (sessionStorage.getItem(sessionKey)) {
      const store = JSON.parse(sessionStorage.getItem(sessionKey));
      Object.assign(state, store);
    }
    return state;
  },
  getters: {
    // カリー化して任意の値を取り出せるようにしておく
    answer: (state) => (key) => {
      return toRaw(state[key]);
    },
    all: (state) => {
      return toRaw(state);
    },
  },
  mutations: {
    setAnswers(state, data) {
      Object.keys(data).forEach((key) => {
        state[key] = data[key];
      });
      sessionStorage.setItem(sessionKey, JSON.stringify(state));
    },
    clear() {
      sessionStorage.setItem(sessionKey, JSON.stringify({}));
      this.replaceState({});
    },
  },
  actions: {
    answer({ commit }, data) {
      if (!(data instanceof Object)) {
        console.error("引数はObjectで渡してください");
        return;
      }
      commit("setAnswers", data);
    },
    clear({ commit }) {
      commit("clear");
    },
  },
};
