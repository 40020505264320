<script>
/*
# Design
conditionの種類は以下を網羅していく方針
https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_elements_condition_operators.html

# Memo
プライベートな関数には $_ をつける
https://jp.vuejs.org/v2/style-guide/index.html
*/

export default {
  name: "Condition",
  methods: {
    $_stringEquals(argObj) {
      const operator = ([variable, value]) =>
        Array.isArray(value)
          ? value.some((v) => this[variable] == v) // 配列の場合は OR で評価する（IAMの評価方法と同じ）
          : this[variable] == value;

      return Object.entries(argObj).every(operator);
      // 評価対象の変数毎にAND条件とする
      // 例えば { clientCode: "aiueo", age: 30 } の場合は clientCodeがaiueo かつ ageが30
      // 例えば { clientCode: ["aiueo", "xyz"], age: 30 } の場合は clientCodeがaiueo または xyz かつ ageが30
    },
    evaluate({ condition = {} }) {
      const predicate = ([typeString, argObj]) =>
        this[`$_${typeString}`](argObj);

      const result = Object.entries(condition).every(predicate);
      return result;
      // typeStringごとにAND条件とする
      // 例えば { stringEquels: { clientCode: "aiueo" }, numericLessThan: { age: 30 } } の場合は
      // cliendCodeがaiueo かつ ageが30未満
    },
  },
};
</script>
